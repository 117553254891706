.editableBanner {
  position: relative;
  border-radius: 10px;
  top: 15px;
  max-width: 1080px;
  min-width: 1000px;
  font-size: 16px;
  background: #404761;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
  padding-top: 1px;
  padding-bottom: 1px;
}
