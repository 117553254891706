.itemContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default to 4 columns */
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: 1px solid #404761;
  margin: 10px;
  padding: 5px;
  min-width: 0;
  max-width: 100%;
}

.itemDescription {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2;
}

.itemDescription p {
  margin: 0;
}

.itemBuyZone {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
}

.noProductsFound {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 1200px) {
  .itemContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1199px) {
  .itemContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .itemContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  /* .itemContainer {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
    margin: 0;
  } */
}
@media (min-width: 768px) {
  .searchBar {
    gap: 1rem;
  }
}

@media (max-width: 576px) {
  .searchBar {
    justify-content: space-evenly;
  }
}
