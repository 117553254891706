.input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  margin: 10px 2px;
  box-shadow: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.input:focus {
  border: 2px solid black;
  outline: none;
}

.inputCheck {
  padding: 10px 10px;
  margin: 10px 2px;
}

.container {
  display: flex;
}

.item {
  flex-basis: 100%;
  padding: 1rem;
}

.topLevel {
  padding-bottom: 1rem;
}

.submitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 50%;
  align-items: center;
}

.paymentSection {
  padding: 1rem;
}

.paymentSection div:first-child {
  padding-bottom: 10px;
  font-weight: 500;
  color: #2c3e50;
}

.select {
  width: 100%;
  max-width: 300px;
}

.postageOptions {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 980px) {
  .button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .item {
    padding: 0.5rem;
  }

  .container {
    flex-direction: column;
  }

  .topLevel {
    padding: 0.5rem;
  }

  .paymentSection {
    padding: 0.5rem;
  }
}
