.loginScreen {
  width: 80%;
  margin: auto;
  text-align: center;
  border: 1px solid lightgray;
}

.loginHeader {
  background-color: lightgray;
  padding: 15px;
  font-size: 26px;
}

.loginInput {
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 40%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginSubmit {
  margin-bottom: 10px;
}

.adminContainer {
  display: grid;
  grid-template-columns: 20% auto;
}
