.itemDetailedContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.itemTitle {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "Book Antiqua";
}

.itemReference {
  font-size: 13px;
  margin-bottom: 20px;
}

.outOfStock {
  color: red;
  margin-bottom: 1rem;
  font-size: 13px;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.itemButtonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 768px) {
  .itemDetailedContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.galleryContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
