.buttonFilterContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.orderContainer {
  max-height: 100vh;
  overflow: scroll;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.buttons {
  padding: 1rem 0;
}
