.postageTable {
  width: 100%;
}

.rates {
  width: 15%;
}

.updateBtn {
  margin-top: 10px;
}

.postageContainer {
  max-height: 100vh;
  overflow: scroll;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.addPostageSubmitButtons{
  display: flex;
  justify-content: right;
  margin-top: 1em;
}
.btnGroup{
  max-width: 25%;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
}
