.stockTable {
  margin-top: 20px;
}

.stockContainer {
  max-height: 100vh;
  width: 100%;
  overflow: scroll;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.editorContainer {
  border: 0.5px solid grey;
  padding: 5px;
  font-size: 14px;
}

.imageUploadButtons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.lastNumberUsed {
  padding-bottom: 1rem;
}

.selectedCategories {
  margin-top: 10px;
}

.selectedCategories ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.selectedCategories li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}
