.navigationMenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px;
  gap: 10px;
  justify-items: center; /* Center items horizontally in grid cells */
  align-items: center; /* Center items vertically in grid cells */
}

.adminMenu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.navigationMenu button {
  width: 100%; /* Slightly reduced to look better centered */
  height: 40px;
  margin: 0 !important; /* Remove margins since we're using grid gap */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center; /* Center button text horizontally */
  align-items: center; /* Center button text vertically */
}

/* GB postal history nav menu */
.regionGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  background-color: #444f76;
  color: white;
}

.regionGridTitle {
  grid-column: 1 / -1;
  text-align: center;
  padding: 0.5rem;
  font-weight: 600;
  font-family: serif;
}

.regionGridItem {
  text-align: center;
}

.divider {
  grid-column: 1 / -1;
  background-color: white;
}

.regionGridItems {
  display: contents;
}

/* Nav button menu grid*/
.navGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  background-color: #444f76;
  color: white;
}

.navGridTitle {
  grid-column: 1 / -1;
  text-align: center;
  padding: 0.5rem;
  font-weight: 600;
}

.navGridItem {
  text-align: center;
}

.navGridItems {
  display: contents;
}

@media (max-width: 1024px) {
  .regionGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .navigationMenu {
    grid-template-columns: repeat(2, 1fr);
  }

  .navigationMenu button {
    font-size: 12px;
  }

  .regionGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
