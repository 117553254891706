.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.treeContainer {
  border: 1px solid lightgray;
}

.actions {
  margin-top: 1rem;
}

.nodes {
  cursor: pointer;
  font-size: 18px;
}
