.app {
  /* max-width: 1080px;
  min-width: 1000px; */
  width: 50%;
  height: 100%;
  margin: auto;
}

@media (max-width: 2000px) {
  .app {
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .app {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .app {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}
