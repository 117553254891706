.editorContainer {
  border: 0.5px solid grey;
  padding: 5px;
}

.staticContainer {
  max-height: 100vh;
  overflow: scroll;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
