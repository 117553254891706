.gallery-item {
  object-fit: cover;
}

.fullscreen {
  width: 50%;
}

.normal {
  width: 100%;
}
