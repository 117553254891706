.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.headerLogo {
  width: 75%;
}

.headerLogo img {
  width: 100%;
  height: auto;
}

.headerBasket {
  text-align: center;
  font-size: 16px;
}

.tagline {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-style: italic;
  font-size: 18px;
  color: #404761;
  padding: 1rem 0;
  border-top: 1px solid #404761;
}

.editableBanner {
  border-radius: 10px;
  width: 100%;
  min-width: auto;
  font-size: 16px;
  background: #404761;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
  padding: 0.25rem;
}

.editableBanner p {
  margin: 8px;
}

@media (max-width: 1024px) {
  .header {
    display: flex;
    flex-direction: row;
  }

  .headerBasket {
    font-size: 14px;
  }

  .editableBanner {
    display: none;
  }

  .headerLogo {
    border-bottom: none;
    border-right: none;
    width: 60%;
  }

  .tagline {
    display: none;
  }
}
