.itemContainer {
  grid-template-columns: repeat(4, 1fr);
}

.item {
  text-align: center;
  height: 300px;
  border: 1px solid #404761;
  margin: 10px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemDescription {
  font-size: 13px;
  height: 75%;
  max-height: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemBuyZone {
  font-size: 13px;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.emptyCart {
  text-align: center;
  padding: 3rem 1rem;
}

.emptyCart h2 {
  margin-bottom: 1rem;
  font-family: "Book Antiqua", serif;
}

.emptyCart p {
  margin-bottom: 1.5rem;
  color: #666;
}

@media (max-width: 1800px) {
  .items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}
