.customerTable {
  margin-top: 20px;
}

.customerContainer {
  max-height: 100vh;
  width: 900px ;
  overflow: scroll;
}

.inlineLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
